<template>
	<div class="publicClass_page">
		<Banner :adCategory="6"></Banner>
		<div class="module1">
			<div class="content">
				<CourseTitle
					title="学员专区视频"
					contText="一线实战大师倾力主讲，前瞻性视角、经典案例培训、施工难点重点精讲"
				>
				</CourseTitle>
				<div class="navBar" v-if="isMrie == true">
					<div
						class="navBarBox flex-center sidebar"
						:class="isMrie == true ? 'isOverflow' : ''"
					>
						<div
							class="list display pointer nowrap"
							:class="navIndex == index ? 'listActive' : ''"
							v-for="(item, index) in navBarList"
							:key="index"
							@click="changeNav(item, index)"
						>
							{{ item.classificationName }}
						</div>
						<div class="more display pointer" @click="moreBtn">
							<span>更多</span>
							<img src="@/assets/img/publicClass/more.png" alt="" />
						</div>
					</div>
				</div>
				<div class="navBar" v-else>
					<swiper ref="mySwiper" :options="swiperOptions">
						<swiper-slide v-for="(item, index) in navBarList" :key="index">
							<div
								class="navBarBox flex-center sidebar"
								:class="isMrie == true ? 'isOverflow' : ''"
							>
								<div
									class="list display pointer nowrap"
									:class="navIndex == index ? 'listActive' : ''"
									@click="changeNav(item, index)"
								>
									{{ item.classificationName }}
								</div>
							</div>
						</swiper-slide>
					</swiper>
					<div class="more display pointer" @click="moreBtn">
						<span>更多</span>
						<img src="@/assets/img/publicClass/more.png" alt="" />
					</div>
				</div>
				<div class="video_box flex flex-wrap">
					<div
						class="list pointer"
						@click="learning(item, 1)"
						v-for="(item, index) in courseList"
						:key="index"
					>
						<img class="icon_xx" :src="item.courseCover" alt="" />
						<div class="video_cont">
							<div class="video_name">{{ item.courseName }}</div>
							<div class="learning Between">
								<div class="fl flex">
									<img
										class="icon_x"
										src="@/assets/img/publicClass/xx.png"
										alt=""
									/>
									<div class="learning_num">
										{{ item.numberStudents }}已学习
									</div>
								</div>
								<div class="fr">
									<div class="learningBtn display">立即学习</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="module2" v-if="freeCourseList.length != 0">
			<div class="content">
				<CourseTitle
					title="免费视频"
					contText="定期免费公开课，你值得拥有"
				></CourseTitle>
				<div class="freeVideo_content flex">
					<div class="freeVideo">
						<div class="videoBox">
							<img
								class="icon_video"
								:src="freeCourseData.courseCover"
								alt=""
							/>
							<div class="mask_laye"></div>
							<div class="video_footer Between">
								<div class="video_footer_fl">
									{{ freeCourseData.courseName }}
								</div>
								<div class="video_footer_fr flex-center">
									<div class="num">
										{{ freeCourseData.numberStudents }}已学习
									</div>
									<div class="btn display pointer" @click="learning('', 2)">
										立即学习
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="freeVideoList">
						<div class="videoList sidebar">
							<div
								class="list flex-center pointer row"
								:class="activeImdex == index ? 'listActive' : ''"
								v-for="(item, index) in freeCourseList"
								:key="index"
								@click="changeBtn(item, index)"
							>
								{{ item.courseName }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="module3">
			<div class="content">
				<div class="live_streaming flex">
					<div class="fl">
						<img
							class="code_img"
							:src="$store.state.Information.publicCourseQrCode"
							alt=""
						/>
						<div class="scan_code">扫码看直播</div>
					</div>
					<div class="fr">
						<div class="title">直播课程</div>
						<div class="line"></div>
						<div class="Interpretation">在线直播教学讲解，剖析施工重难点</div>
						<div class="details">
							果尔佳直播课堂，是果尔佳为培训建筑行业技能人才，搭建理论与实战相结合的视频分享平台，涉及室内装饰工程、建筑工程、八大员、工程技术、工程设计等内容的直播和点播视频。
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="module4">
			<div class="content">
				<FreeClasses :imgUrl="imgUrl"></FreeClasses>
			</div>
		</div>

		<div class="video_mask_layer" v-if="videoShow == true">
			<div class="mask_layer"></div>
			<div class="video_cont">
				<img
					class="offBtn pointer"
					@click="videoShow = false"
					src="@/assets/img/home/off.png"
					alt=""
				/>
				<video class="videoCont" :src="videoUrl" width="100%" controls></video>
			</div>
		</div>
	</div>
</template>
<script>
import Banner from '@/components/banner'
import CourseTitle from './components/courseTitle.vue'
import FreeClasses from '@/components/freeClasses.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
	components: {
		Banner,
		CourseTitle,
		FreeClasses,
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			videoShow: false,
			videoUrl: '',
			navIndex: 0,
			activeImdex: 0,
			isMrie: false,
			imgUrl: require('@/assets/img/publicClass/bg.png'),
			navBarList: [],
			learningVideo: [],
			videoList: [],

			courseList: [],
			freeCourseList: [],
			freeCourseData: {},
			params: {
				page: 1,
				limit: 10,
				courseType: 1,
				typeWorkId: '',
			},
			paramss: {
				page: 1,
				limit: 10,
				courseType: 2,
				typeWorkId: '',
			},
			swiperOptions: {
				slidesPerView: 7,
				spaceBetween: 100,
				pagination: {
					el: '.swiper-pagination', //与slot="pagination"处 class 一致
					clickable: true, //轮播按钮支持点击
				},
			},
			paramsVideo: {
				id: '',
				token: '',
			},
		}
	},
	mounted() {
		this.getData()
		this.getDatas()
		this.getClass()
	},
	methods: {
		getData() {
			this.api.findPublicCourse(this.params).then((res) => {
				this.courseList = res.data || []
			})
		},
		getDatas() {
			this.api.findPublicCourse(this.paramss).then((res) => {
				this.freeCourseList = res.data || []
				if (this.freeCourseList.length != 0) {
					this.freeCourseData = this.freeCourseList[0]
					this.videoUrl = this.freeCourseData.videoUrl
				} else {
					this.freeCourseData = []
				}
			})
		},
		getClass() {
			this.api.findTwoTypeWork().then((res) => {
				let obj = {
					id: '',
					classificationName: '全部',
				}
				res.data.unshift(obj)
				this.navBarList = res.data || []
			})
		},
		changeNav(item, index) {
			this.navIndex = index
			this.params.typeWorkId = item.id
			this.paramss.typeWorkId = item.id
			this.getData()
			this.getDatas()
		},
		moreBtn() {
			this.isMrie = !this.isMrie
		},
		changeBtn(item, index) {
			this.freeCourseData = item
			this.activeImdex = index
		},
		learning(item, type) {
			let time = localStorage.getItem('time')
			let userInfo = JSON.parse(localStorage.getItem('userInfo'))
			if (userInfo != null) {
				if (new Date().getTime() - time > 1000 * 1800) {
					this.$router.push('/homes/login')
					this.$message.error('登录超时，请重新登录')
					localStorage.removeItem('time')
				} else {
					if (type == 1) {
						this.videoShow = true
						this.paramsVideo = {
							id: item.id,
							token: userInfo.token,
						}
						this.api.getPublicCourseVideo(this.paramsVideo).then((res) => {
							this.videoUrl = res.data
						})
					} else {
						this.videoShow = true
						this.videoUrl = this.freeCourseData.videoUrl
					}
				}
			} else {
				this.$router.push('/homes/login')
				this.$message.error('请登录后观看')
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.publicClass_page {
	.banner {
		width: 100%;

		img {
			height: 540px;
		}
	}

	.module1 {
		height: 955px;

		.navBar {
			width: 1200px;
			position: relative;
			margin-top: 30px;
			.more {
				width: 84px;
				height: 40px;
				background: white;
				position: absolute;
				top: 0px;
				right: -84px;
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				z-index: 2;

				img {
					width: 18px;
					height: 9px;
					margin-left: 4px;
				}
			}
			.isOverflow {
				overflow: none !important;
				overflow-x: none !important;
				flex-wrap: wrap;
			}
			::v-deep.swiper-slide {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			::v-deep.swiper-slide:nth-child(1) {
				margin-left: 40px !important;
			}
			.navBarBox {
				.listActive {
					background: #ff881e !important;
					color: white !important;
				}

				.list {
					width: 150px;
					height: 36px;
					background: #f5f5f5;
					border-radius: 18px;
					padding: 0px 20px;
					margin-left: 20px;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					margin-bottom: 10px;
				}
			}
		}

		.video_box {
			margin-top: 30px;

			.list:nth-child(4n + 1) {
				margin-left: 0px !important;
			}

			.list {
				width: 284px;
				height: 272px;
				background: white;
				box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);
				margin-left: 21px;
				margin-bottom: 23px;

				.icon_xx {
					width: 284px;
					height: 174px;
				}

				.video_cont {
					height: 98px;
					padding: 18px 15px 18px 13px;
					box-sizing: border-box;

					.video_name {
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 16px;
					}

					.learning {
						margin-top: 10px;

						.fl {
							.icon_x {
								width: 18px;
								height: 18px;
							}

							.learning_num {
								font-size: 14px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #ff881e;
								margin-left: 5px;
							}
						}

						.fr {
							.learningBtn {
								width: 98px;
								height: 36px;
								background: #ff881e;
								border: 1px solid #ff881e;
								border-radius: 3px;
								font-size: 16px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #ffffff;
							}
						}
					}
				}
			}
		}
	}

	.module2 {
		height: 820px;
		background: #fafafa;

		.freeVideo_content {
			width: 100%;
			height: 540px;
			margin-top: 31px;
			background: #ffffff;

			.freeVideo {
				.videoBox {
					width: 800px;
					height: 540px;
					position: relative;

					.icon_video {
						width: 800px;
						height: 540px;
					}

					.mask_laye {
						width: 800px;
						height: 55px;
						background: #000;
						opacity: 0.8;
						position: absolute;
						left: 0;
						bottom: 0;
					}

					.video_footer {
						width: 800px;
						height: 55px;
						position: absolute;
						left: 0;
						bottom: 0;
						padding-left: 27px;
						padding-right: 30px;
						box-sizing: border-box;

						.video_footer_fl {
							font-size: 18px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #ffffff;
						}

						.video_footer_fr {
							.num {
								font-size: 14px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #ff881e;
							}

							.btn {
								width: 98px;
								height: 36px;

								background: #ff881e;
								border: 1px solid #ff881e;
								border-radius: 3px;
								font-size: 16px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #ffffff;
								margin-left: 39px;
							}
						}
					}
				}
			}

			.freeVideoList {
				width: 400px;
				padding: 28px 18px 30px 20px;
				box-sizing: border-box;

				.videoList {
					height: 487px;
					overflow: hidden;
					overflow-y: scroll;

					.listActive {
						background: #ff881e !important;
						color: white !important;
						border: 1px solid#FF881E !important;
					}

					.list {
						width: 335px;
						height: 48px;
						background: #ffffff;
						border: 1px solid #dedede;
						border-radius: 22px;
						padding-left: 20px;
						font-size: 16px;
						box-sizing: border-box;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						margin-bottom: 14px;
					}
				}
			}
		}
	}

	.module3 {
		height: 588px;
		background: url('../../assets/img/publicClass/bg1.png') no-repeat;
		background-size: 100% 100%;

		.live_streaming {
			padding-top: 120px;
			padding-left: 80px;
			box-sizing: border-box;

			.fl {
				.code_img {
					width: 320px;
					height: 320px;
				}

				.scan_code {
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ffffff;
					line-height: 18px;
					text-align: center;
					margin-top: 20px;
				}
			}

			.fr {
				margin-left: 100px;

				.title {
					font-size: 48px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #ffffff;
					line-height: 48px;
					margin-top: -6px;
				}

				.line {
					width: 78px;
					height: 2px;
					background: #ff881e;
					margin-top: 33px;
					margin-bottom: 33px;
				}

				.Interpretation {
					font-size: 30px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ffffff;
					line-height: 30px;
				}

				.details {
					width: 613px;
					height: 91px;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ffffff;
					line-height: 36px;
					margin-top: 61px;
				}
			}
		}
	}

	.module4 {
		height: 658px;
		padding-top: 95px;
	}
}

.video_mask_layer {
	.video_cont {
		width: 900px;
		height: 550px;
		background: white;
		position: fixed;
		top: 50%;
		left: 50%;
		margin-top: -275px;
		margin-left: -450px;
		z-index: 10;
		padding: 20px;
		box-sizing: border-box;

		.videoCont {
			width: 100%;
			height: 100%;
		}

		.offBtn {
			width: 20px;
			height: 20px;
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}
</style>