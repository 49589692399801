<template>

    <div class="free_classes flex">
        <img class="icon_school" :src="imgUrl" alt="">
        <div class="cont ">
            <div class="title display">拉开你和同龄人距离的就是
            </div>
            <div class="title free display">这节免费试听课!</div>
            <div class="allFree display">试听过程中全程免费 全面了解行业和讲师 提前进入学习状态</div>
            <div class="formData">
                <div class="list flex-center">
                    <input type="text" v-model="formData.userName" placeholder="如何称呼您？">
                </div>
                <div class="">
                    <el-select v-model="formData.courseId" placeholder="选择试听课程" @change="selectBtn">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="list flex-center">
                    <input type="text" v-model="formData.phoneNumber" maxlength="11" placeholder="您的手机号？">
                </div>
                <div class="btn display pointer" @click="submitBtn">申请免费试听</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        imgUrl:{}
    },
    data(){
        return{
            options:[],
            formData:{
                userName: "",
                phoneNumber: "",
                courseId: "",
                courseType:""
            }
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            this.api.findAllCourse().then(res=>{
                if(res.code == 0){
                    let arr =[]
                    let obj = {}
                    res.data.forEach(item=>{
                        obj = {
                            value: item.id,
                            label: item.courseName,
                            courseType: item.courseType,
                        }
                        arr.push(obj)
                    })
                    this.options = arr
                }
            })
        },
        selectBtn(val) {
            this.options.forEach(item => {
                if (item.value == val) {
                    this.formData.courseType = item.courseType
                }
            })
        },
        submitBtn(){
            if (this.formData.userName == ''){
                return this.$message.error('请输入您的姓名')
            }
            if (this.formData.courseId == '') {
                return this.$message.error('请选择课程')
            }
            if (!/^[0-9]{11}$/.test(this.formData.phoneNumber)) {
                return this.$message.error('请正确输入手机号')
            }
            this.api.addCourseApply(this.formData).then(res=>{
                if(res.code == 0){
                    this.$message.success('成功！')
                    this.formData = {
                        userName: "",
                        phoneNumber: "",
                        courseId: "",
                        courseType: ""
            }
                }
            })
        },
    }
}
</script>
<style lang="scss" scoped>

         .free_classes {
             height: 464px;
             box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);
            background: white;
             .icon_school {
                 width: 610px;
                 height: 464px;
             }
    
    
    
             .cont {
                 width: 590px;
                 height: 456px;
                 padding-top: 40px;
                 padding-top: 40px;
                 box-sizing: border-box;
    
                 .title {
                     font-size: 28px;
                     font-family: Microsoft YaHei;
                     font-weight: bold;
                     color: #003C7E;
                     line-height: 36px;
                 }
    
                 .free {
                     color: #FF881E;
                 }
    
                 .allFree {
                     font-size: 16px;
                     font-family: Microsoft YaHei;
                     font-weight: 400;
                     color: #666666;
                     line-height: 16px;
                     margin-top: 15px;
                 }
    
                 .formData {
                     width: 478px;
                     margin: 0 auto;
    
                     .list {
                         width: 478px;
                         height: 48px;
                         background: #FFFFFF;
                         border: 1px solid #DEDEDE;
                         margin-top: 20px;
                         padding-left: 20px;
    
                         input {
                             height: 40px;
                             font-size: 16px;
                         }
                     }
    
                     .btn {
                         width: 478px;
                         height: 54px;
                         background: #FF881E;
                         font-size: 20px;
                         font-family: Microsoft YaHei;
                         font-weight: 400;
                         color: #FFFFFF;
                         margin-top: 20px;
                     }
    
                     ::v-deep.el-input__inner {
                         width: 478px;
                         height: 48px;
                         background: #FFFFFF;
                         border: 1px solid #DEDEDE;
                         margin-top: 20px;
                         padding-left: 20px;
    
                     }
    
                     ::v-deep.el-input__icon {
                         margin-top: 20px;
                         height: 48px;
                         padding-left: 6px;
    
                     }
    
                     ::v-deep.el-icon-arrow-up:before {
                         font-size: 24px;
                     }
                 }
             }
    
         }
</style>